/* Global imports */
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Img from "gatsby-image";
import moment from "moment";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";

/* Local imports */
import SEO from "../modules/seo";
import Label from "../components/label";
import Moderators from "../components/moderators";
import Box from "../components/sidebar/box";
import Speakers from "../components/speakers";
import Link, { getFilteredSearchLink } from "../helper/link";
import { getValue } from "../helper/translation";
import Breadcrumb from "../modules/breadcrumb";
import Layout from "../modules/layout";
import News from "../modules/news";
import Gallery from "../components/gallery";
import { convertTZ } from "../helper/timezone";

/* Component definition */
const SessionDetail = ({ data: { session }, pageContext }) => {
  const {
    description,
    endTime,
    format,
    gallery,
    imageGallery = [],
    hosts,
    id,
    info,
    externalLink,
    mainTopic,
    moderators,
    name: sessionName,
    region,
    room,
    secondaryTopic,
    speakers,
    startTime,
    subtitle,
    title,
    topic,
  } = session;
  const {
    name,
    contentComponents,
    node_locale,
    settings,
    breadcrumbs,
    showBreadcrumbNavigation,
    showNews,
  } = pageContext;

  const siteSettings = settings;
  const { microcopy } = settings;

  // Pass the times to a +00:00 Timezone Country to appear the correct hours (Contentful gives +01:00)
  const endTimeCorrected = convertTZ(endTime, "Europe/Lisbon");
  const startTimeCorrected = convertTZ(startTime, "Europe/Lisbon");

  const objImages = {
    images: [],
  };
  if (imageGallery) {
    imageGallery.map(image => {
      objImages["images"].push(image.imageLandscape);
    });
  }

  moment.locale(node_locale.split("-")[0]);

  const todayDate = moment(new Date()).format("YYYY-MM-DD");

  const getStringFromTagId = (tagId, topic) => {
    if (topic) {
      const relatedString = topic.find(
        top => top && top.contentful_id == tagId
      );
      return relatedString ? relatedString.name : "";
    }
    return null;
  };

  return (
    <Layout siteSettings={siteSettings}>
      <SEO
        title={`${siteSettings.siteName} - ${title}`}
        description={siteSettings.siteDescription.siteDescription}
        author={siteSettings.siteAuthor}
      />
      <div className="sessionDetailBreadcrumb">
        <Breadcrumb
          visible={showBreadcrumbNavigation}
          breadcrumbs={breadcrumbs}
        ></Breadcrumb>
      </div>
      <Container fluid className="sessionPageContainer pt-5">
        <Row className="sessionContent">
          <Col sm={12} md={12} lg={4} xl={4} className="sidebarContainer">
            {hosts && (
              <div className="sidebarInsideContainer">
                <Box
                  headline={`${getValue("labels.hosts", microcopy)}`}
                  className="sidebarBox"
                >
                  <div>
                    {hosts.map(host => (
                      <div className="hostItemContainer">
                        <p className="font-weight-bold hostTitle">
                          {host.name}
                        </p>
                        <hr></hr>
                        {/* <div className="hostImage">
                          <Img 
                            fluid={host.image.fluid}
                            alt={host.name}
                          />
                        </div> */}
                        <div className="icon-card-content">
                          <div className="top">
                            <Img
                              className="icon d-block w-100"
                              fluid={host.image.fluid}
                              alt={host.name}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Box>
              </div>
            )}
            {moment(endTime).isAfter(todayDate) && (
              <div className="sidebarInsideContainer">
                <Box
                  headline={`${getValue("labels.info", microcopy)}`}
                  className="sidebarBox"
                >
                  <div className="infoBox">
                    <div>
                      <span>{moment(startTime).format("DD MMMM YYYY")}</span>
                      <br></br>
                      <span>
                        {moment(startTimeCorrected)
                          .locale("en")
                          .format("h:mm a")}{" "}
                        -{" "}
                        {moment(endTimeCorrected)
                          .locale("en")
                          .format("h:mm a")}
                      </span>
                      <br></br>
                      {room && <span>{room.title}</span>}
                      {externalLink && (
                        <div className="btnContainer">
                          <Link
                            className="button button--inverted button--link"
                            to={{ url: externalLink.url }}
                          >
                            {externalLink.linkText}
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </Box>
              </div>
            )}
            {info &&
              info.map((inf, index) => {
                return (
                  <Box headline={`${inf.title}`} className="sidebarBox">
                    <div>{inf.description.description}</div>
                  </Box>
                );
              })}
          </Col>
          <Col sm={12} md={12} lg={8} xl={8}>
            <article
              className={`teaser-card 
      
              `}
            >
              <Container className="p-0">
                {/* <Row noGutters={!big}> */}
                <Row>
                  <Col
                    className="d-flex align-items-center "
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <div className="teaser-card-content">
                      <div className="tags">
                        {mainTopic && (
                          <Label
                            text={getStringFromTagId(mainTopic, [
                              ...topic,
                              region,
                            ])}
                            type="primary"
                            className="tag text-white font-weight-normal"
                            link={getFilteredSearchLink(
                              format.contentful_id,
                              node_locale
                            )}
                          />
                        )}
                        {secondaryTopic && (
                          <Label
                            text={getStringFromTagId(secondaryTopic, [
                              ...topic,
                              region,
                            ])}
                            type="secondary"
                            className="tag"
                            link={getFilteredSearchLink(
                              format.contentful_id,
                              node_locale
                            )}
                          />
                        )}
                      </div>
                      <h6 className="font-weight-bold titleBehingTags pt-4 pb-4">
                        {sessionName}
                      </h6>
                      <h2>{title}</h2>
                      <h2 className="teaser-card-title">{subtitle}</h2>
                      <div className="teaser-card-text">
                        {documentToReactComponents(description.json)}
                      </div>
                      {/* SPEAKERS */}
                      {speakers && (
                        <Speakers
                          speakers={speakers}
                          node_locale={node_locale}
                        />
                      )}
                      {/* MODERATORS */}
                      {moderators && (
                        <Moderators
                          moderators={moderators}
                          node_locale={node_locale}
                        />
                      )}
                      {imageGallery && (
                        <div className="fixedGallery">
                          <Gallery
                            {...objImages}
                            node_locale={node_locale}
                            needsTitle={true}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </Container>
            </article>
          </Col>
        </Row>
      </Container>
      <News
        node_locale={siteSettings.node_locale}
        settings={siteSettings}
        visible={true}
      />
    </Layout>
  );
};

export const pageQuery = graphql`
  query($contentful_id: String!, $node_locale: String!) {
    session: contentfulSession(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $node_locale }
    ) {
      id
      title
      name
      subtitle
      mainTopic
      secondaryTopic
      externalLink {
        name
        url
        linkText
      }
      reader {
        json
      }
      description {
        description
        json
      }
      speakers {
        ... on ContentfulPerson {
          id
          contentful_id
          name
          organization {
            name
          }
          image {
            fluid {
              base64
              sizes
              src
              srcSet
              srcWebp
              aspectRatio
            }
          }
          biography {
            json
            biography
          }
        }
      }
      moderators {
        ... on ContentfulPerson {
          id
          contentful_id
          name
          organization {
            name
          }
          image {
            fluid {
              base64
              sizes
              src
              srcSet
              srcWebp
              aspectRatio
            }
          }
          biography {
            json
            biography
          }
        }
      }
      startTime
      endTime
      hosts {
        ... on ContentfulHost {
          name
          link
          image {
            fluid {
              base64
              sizes
              src
              srcSet
              srcWebp
              aspectRatio
            }
            fixed(width: 150, quality: 100) {
              src
              srcSet
              srcSetWebp
              srcWebp
              height
              width
            }
          }
        }
      }
      info {
        ... on ContentfulInfo {
          title
          description {
            description
          }
        }
      }
      region {
        ... on ContentfulRegion {
          contentful_id
          name
        }
      }
      format {
        ... on ContentfulFormat {
          contentful_id
          title
        }
      }
      topic {
        ... on ContentfulTopic {
          id
          contentful_id
          name
        }
      }

      imageGallery {
        name
        imageLandscape {
          fluid {
            base64
            sizes
            src
            srcSet
            srcWebp
            aspectRatio
          }
        }
      }
      room {
        ... on ContentfulRoom {
          title
        }
      }
    }
  }
`;

/* PropTypes */
SessionDetail.propTypes = {};
SessionDetail.defaultProps = {};

/* Local utility functions */

/* Styles */

export default SessionDetail;
